<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Toast />
    <ConfirmDialog />
    <cmsHeader />
    <div class="container">
      <div class="content">
      <div class="title">Events : List </div>
      Below is a list of Events sorted by date. A checkmark will indicate it as a Summer Session event (SS). 
      To add an event, click ADD EVENT. To edit an event, click the the title. The default trainer will be added 
      automatically (can be edited or deleted). To add a trainer to an event click the  button in the Trainers column. 
      To view the registrants list for an event click its  icon. To copy an event click the  icon. To delete an 
      event, click .
    </div>
      <div class="action-bar">
        <router-link to="/cms/event/add" class="btn-orange btn btn-default"> ADD EVENT </router-link>
      </div>
    <table class="datatable" v-if="events">
      <th>Date</th>
      <th>Title</th>
      <th>ss</th>
      <th>Trainers</th>
      <th>Registration Status</th>
      <th>Registrants</th>
      <th>Copy</th>
      <th>Event Status</th>
      <th width="110px">Actions</th>
      <tr v-if="events == null">
        <td colspan="9" style="text-align:center;background-color:rgba(242, 233, 228, 0.2);">No Events Available.</td>
      </tr>
      <tr v-for="event in events" :key="event.id">
        <td style="width: 100px">{{ postDate(event.date) }}</td>
        <td>{{ event.title }}</td>
        <td>{{ event.isSummerSession }}</td>
        <td style="width: 175px;text-align:center;">{{ event.trainers ? event.trainers.length:"NA" }}</td>
        <td>{{ eventRegistrationStatus(event.regOpen,event.regClose,event.regCount,event.seats) }}</td>
        <td>{{ event.regCount ? event.regCount:0 }}/{{ event.seats }}</td>
        <td></td>
        <td>{{ event.eventStatus }}</td>
        <td>
          <button class="button" @click="editEvent(event)">Edit</button> 
          <button class="button" @click="confirmDelete(event)">Delete</button></td>
      </tr>
    </table>
    </div>
    <cmsFooter />
  </div>
</template>

<script>
// 3rd Party Libraries and Helpers
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Dates from '../../../methods/Date.js'

// Primevue
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'

// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// API Services
import EventServices from '../../../services/EventServices.js'
import CmsServices from '../../../services/CmsServices.js'

export default {
  name: 'cmsEventList',
  components: {
    cmsHeader,
    cmsFooter,
    ConfirmDialog
  },
  setup() {
    const toast = useToast()
    const store = useStore()
    const confirm = useConfirm()
    const router = useRouter()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const events = ref([])

    // Load the store with the events list
    store.dispatch('fetchCMSEventsList')
    // Load the store with the events
    store.dispatch('fetchCMSEvents')

    CmsServices.getEventsList()
    .then((response) => {
      if(response.status === 200) {
        events.value = response.data
      }
    })

    const postDate = (value) => {
      return Dates.shortDate(value)
    }

    const editEvent = (event) => {
      const trainerGuid = event.trainerGuid
      const eventGuid = event.guid
      router.push({ name:"cmsEventEdit", query:{ trainerGuid: trainerGuid, eventGuid: eventGuid}})
    }

    const eventRegistrationStatus = (open,close,registrations,seats) => {
      if (close >= Date.now() && open <= Date.now() && registrations < seats) {
        return "Open" 
      } else { 
        return "Closed"
      }
    }

    const deleteEvent = (event) => {
      EventServices.deleteEvent(event.guid)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.message, life: 5000})
        } else {
          toast.add({severity: 'success', summary: 'Success', detail: "Event has been deleted!", life: 5000})
          events.value.splice(events.value.indexOf(event),1)
        }
      })
    }

    const confirmDelete = (event) => {
      confirm.require({
        message: 'Do you want to delete this event?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          deleteEvent(event)
        }
      })
    }

    return {
      toast,
      environment,
      events,
      postDate,
      editEvent,
      eventRegistrationStatus,
      confirmDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
}
.title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}
.content {
  margin-top: 10%;
  text-align: left;
}
.action-bar {
  display: grid;
  justify-content: right;
  margin-top: 5%;
}
.datatable {
  margin-top: 1%;
  margin-bottom: 15%;
}
tr:nth-child(2n) {
  background-color: rgba(242, 233, 228, 0.5);
}
th {
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(201, 173, 167, 1);
  background-color: rgba(242, 233, 228, 1);
}
td {
  text-align: left;
  padding-right: 2px;
  
}
.button {
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
